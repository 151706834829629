
import Header from './common/Header.js';
import Main from './blogs/Index.js';
import React from 'react';
import ElaborateFooter from './common/ElaborateFooter.js';

function Blog() {
 return (
   <React.Fragment>
      <Header/>
      <Main/>
      <ElaborateFooter/>
  </React.Fragment>
)
}

export default Blog;
