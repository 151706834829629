import AppScreenshot from '../assets/AppScreenshot.png'
export default function Example() {

  return (
    <div className="bg-gray-900">

      <div className="relative isolate pt-14">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
        </div>
        <div className="py-24 sm:py-32 lg:pb-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
	            <p class="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Coming Soon</p>
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
		Insights you can trust & reviews you can rely on
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-300">
		A trusted platform for authentic reviews and valuable insights, helping you make informed choices with confidence. Stay connected with real opinions and expert recommendations across the latest trends.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

