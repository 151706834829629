// src/Blog.js
import React from 'react';

const Blog = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg mt-10">
      {/* Blog Title */}
      <h1 className="text-4xl font-bold text-gray-800 mb-6">
        The Ultimate Minimal Skincare Routine for Beginners: A Guide for Indian Women
      </h1>

      {/* Introduction */}
      <section className="mb-6">
        <p className="text-lg text-gray-700 mb-4">
          In a world overflowing with beauty products and skincare trends, it can be overwhelming to navigate what works best for our skin. However, a minimal skincare routine can be both effective and simple, especially for beginners. This guide aims to educate Indian women of all skin types and tones on how to achieve a healthy, glowing complexion with minimal effort. Embracing a basic routine not only saves time but also allows your skin to breathe and rejuvenate.
        </p>
      </section>

      {/* Understanding Your Skin Type */}
      <section className="mb-8">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Understanding Your Skin Type</h2>
        <p className="text-lg text-gray-700 mb-4">
          Before diving into a skincare routine, it’s crucial to understand your skin type. Indian skin can vary significantly, so recognizing your unique needs is the first step.
        </p>

        {/* Different Skin Types */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Different Skin Types:</h3>
        <ul className="list-disc pl-8 text-lg text-gray-700 mb-4">
          <li><strong>Normal:</strong> Balanced skin that is neither too oily nor too dry, often requiring minimal maintenance.</li>
          <li><strong>Oily:</strong> Characterized by excess shine, enlarged pores, and a tendency for acne breakouts.</li>
          <li><strong>Dry:</strong> Lacks moisture, appearing flaky or rough, and may feel tight after cleansing.</li>
          <li><strong>Combination:</strong> Oily in the T-zone (forehead, nose, chin) while dry on the cheeks.</li>
          <li><strong>Sensitive:</strong> Prone to irritation, redness, or allergic reactions; requires gentle products.</li>
        </ul>

        {/* How to Determine Your Skin Type */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-2">How to Determine Your Skin Type:</h3>
        <p className="text-lg text-gray-700 mb-4">
          One of the easiest methods is the <strong>bare-faced test</strong>: cleanse your skin and wait for an hour without applying any products. Observe how your skin behaves:
        </p>
        <ul className="list-disc pl-8 text-lg text-gray-700 mb-4">
          <li>If it feels tight and looks flaky, you likely have dry skin.</li>
          <li>If it becomes shiny and greasy, you may have oily skin.</li>
          <li>A mix of both indicates combination skin.</li>
        </ul>
        <p className="text-lg text-gray-700">
          Always patch test new products on a small area of your skin to avoid potential reactions.
        </p>
      </section>

      {/* Essential Steps for a Minimal Skincare Routine */}
      <section className="mb-8">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Essential Steps for a Minimal Skincare Routine</h2>

        {/* Step 1: Cleansing */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-2">1. Cleansing</h3>
        <p className="text-lg text-gray-700 mb-4">
          Cleansing is the cornerstone of any skincare routine. It helps remove dirt, makeup, and excess oil, preparing your skin for the next steps.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          <strong>Recommendation:</strong> Choose a gentle cleanser suited for your skin type. For oily skin, opt for a gel-based cleanser with salicylic acid. If you have dry skin, a cream-based cleanser with hydrating ingredients like glycerin works best.
        </p>

        {/* Step 2: Toning */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-2">2. Toning</h3>
        <p className="text-lg text-gray-700 mb-4">
          Toning helps restore your skin's pH balance after cleansing and provides an extra layer of hydration.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          <strong>Recommendation:</strong> Look for alcohol-free toners with soothing ingredients. <em>Rose water</em> is a great option for all skin types, while <em>aloe vera gel</em> can calm sensitive skin.
        </p>

        {/* Step 3: Moisturizing */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-2">3. Moisturizing</h3>
        <p className="text-lg text-gray-700 mb-4">
          Hydration is key, regardless of your skin type. Moisturizers help seal in moisture and keep your skin soft.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          <strong>Recommendation:</strong> For oily skin, consider a lightweight, oil-free moisturizer. If your skin is dry, opt for a richer cream or a gel-cream hybrid.
        </p>

        {/* Step 4: Sunscreen */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-2">4. Sunscreen</h3>
        <p className="text-lg text-gray-700 mb-4">
          Never skip sunscreen! It's essential for protecting your skin from harmful UV rays, preventing premature aging, and reducing the risk of skin cancer.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          <strong>Recommendation:</strong> Choose a broad-spectrum sunscreen with an SPF of at least 30. Gel-based formulations are ideal for oily skin, while cream formulations work well for dry skin.
        </p>
      </section>

      {/* Additional Considerations */}
      <section className="mb-8">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Additional Considerations</h2>

        {/* Exfoliation */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Exfoliation (1-2 Times a Week)</h3>
        <p className="text-lg text-gray-700 mb-4">
          Exfoliating helps remove dead skin cells and promotes cell turnover, revealing a brighter complexion.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          <strong>Recommendation:</strong> Use a gentle physical exfoliant (like a scrub) or a chemical exfoliant containing AHAs (alpha hydroxy acids) or BHAs (beta hydroxy acids). For sensitive skin, consider mild exfoliating products.
        </p>

        {/* Serums and Treatments */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Serums and Treatments</h3>
        <p className="text-lg text-gray-700 mb-4">
          As you get comfortable with your routine, you might want to introduce serums. These targeted treatments can address specific concerns, such as dark spots or uneven texture.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          <strong>Recommendation:</strong> Start with a <em>Vitamin C serum</em> for brightening and protection against environmental stressors or a <em>hyaluronic acid serum</em> for added hydration.
        </p>
      </section>

      {/* Product Recommendations */}
      <section className="mb-8">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Product Recommendations</h2>
        <p className="text-lg text-gray-700 mb-4">
          Here’s a list of recommended products categorized by skin type:
        </p>
        
        {/* Oily Skin */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Oily Skin:</h3>
        <ul className="list-disc pl-8 text-lg text-gray-700 mb-4">
          <li><strong>Cleanser:</strong> Neutrogena Oil-Free Acne Wash</li>
          <li><strong>Toner:</strong> Plum Green Tea Alcohol-Free Toner</li>
          <li><strong>Moisturizer:</strong> The Body Shop Tea Tree Mattifying Lotion</li>
          <li><strong>Sunscreen:</strong> La Roche-Posay Anthelios Gel Cream</li>
        </ul>

        {/* Dry Skin */}
        <h3 className="text-2xl font-semibold text-gray-800 mb-2">Dry Skin:</h3>
        <ul className="list-disc pl-8 text-lg text-gray-700 mb-4">
          <li><strong>Cleanser:</strong> Cetaphil Gentle Skin Cleanser</li>
          <li><strong>Toner:</strong> Klairs Supple Preparation Unscented Toner</li>
          <li><strong>Moisturizer:</strong> Neutrogena Hydro Boost Water Gel</li>
          <li><strong>Sunscreen:</strong> Biore UV Aqua Rich Watery Essence</li>
        </ul>
      </section>

      {/* Conclusion */}
      <section className="mb-8">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Conclusion</h2>
        <p className="text-lg text-gray-700 mb-4">
          A minimal skincare routine can go a long way in improving your skin’s overall health and appearance. By understanding your skin type and using the right products, you can maintain a glowing complexion with ease. Remember, consistency is key, and your skin will thank you for the care and attention you give it.
        </p>
      </section>
    </div>
  );
};

export default Blog;

