import './App.css';
import React from 'react';
import publicRoutes  from "./routes/"
import { BrowserRouter as Router, Routes,Route } from "react-router-dom"
import NotFound from './NotFound.js';

function App() {
  return (
   <React.Fragment>
        <Router>
          <Routes>
            {publicRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<route.component/>}
                key={idx}
                isAuthProtected={false}
              />
            ))}
	    <Route path='*' element={<NotFound />}/>
         </Routes>
      </Router>
  </React.Fragment>
  );
}

export default App;
