
import React from 'react'
import Header from '../common/Header.js';
import ElaborateFooter from '../common/ElaborateFooter.js';

export default function RefundPolicy() {

return ( 
<>
<div className="ml-10 mt-10 mb-10">
	<p>Last updated 11th March 2024</p>
<br/>
<br/>

<h2>REFUNDS</h2>
<br/>
<br/>

<p>All sales are final and no refund will be issued.</p>
<br/>
<br/>

<h2>QUESTIONS</h2>
<br/>
<br/>

<p>If you have any questions concerning our return policy, please contact us at: <a href="mailto:admin@graphia.ai">admin@graphia.ai</a></p>
<br/>
<br/>
</div>
<ElaborateFooter/>

</>

)

}
