
import LandingPage from '../landingpage/index.js'
import PrivacyPolicy from '../policies/Privacy.js'
import RefundPolicy from '../policies/RefundPolicy.js'
import TermsAndConditions from '../policies/TermsAndConditions.js'
import Blog from '../Blog.js';
import Blog1 from '../blogs/Blog1.js';
import Blog2 from '../blogs/Blog2.js';
import Blog3 from '../blogs/Blog3.js';


const publicRoutes = [
  { path : "/", component: LandingPage},
  { path: "/privacy-policy", component: PrivacyPolicy},
  { path: "/refund-policy", component: RefundPolicy},
  { path: "/terms-of-service", component: TermsAndConditions },
  { path: "/blogs", component: Blog },
  { path: "/blogs/2024-10-22/the-ultimate-minimal-skincare-routine-for-beginners-a-guide-for-indian-women", component: Blog1},
  { path: "/blogs/2024-10-30/top-indian-cosmetics-brands-that-rival-international-labels", component: Blog2},
  { path: "/blogs/2024-11-02/the-ultimate-guide-to-curly-hair-care-and-maintenance", component: Blog3},
	 

  

]

export default publicRoutes
