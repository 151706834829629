
const posts = [
   {
    id: 3,
    title: 'The Ultimate Guide to Curly Hair Care and Maintenance',
    href: '/blogs/2024-11-02/the-ultimate-guide-to-curly-hair-care-and-maintenance',
    description: "This article discusses how to care for and maintain curly and wavy hair",
    imageUrl:
	"https://plus.unsplash.com/premium_photo-1682098317954-252ddfcda75e?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    date: 'Nov 2, 2024',
    datetime: '2024-11-02',
    category: { title: 'hair-care', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 2,
    title: 'Top Indian Cosmetics Brands That Rival International Labels',
    href: '/blogs/2024-10-30/top-indian-cosmetics-brands-that-rival-international-labels',
    description: "This article discusses innovation, quality, and affordability of indian cosmetics brands.",
    imageUrl:
	"https://images.unsplash.com/photo-1600634999623-864991678406?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    date: 'Oct 30, 2024',
    datetime: '2024-10-30',
    category: { title: 'cosmetics', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },

  {
    id: 1,
    title: 'The Ultimate Minimal Skincare Routine for Beginners: A Guide for Indian Women',
    href: '/blogs/2024-10-22/the-ultimate-minimal-skincare-routine-for-beginners-a-guide-for-indian-women',
    description: "This article gives an intro into skincare for different skin types for Indian women.", 
    imageUrl:
	"https://plus.unsplash.com/premium_photo-1706044172736-6671e1849816?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    date: 'Oct 22, 2024',
    datetime: '2024-10-22',
    category: { title: 'guides', href: '#' },
    author: {
      name: 'Pratiksha Dake',
      role: 'Founder',
      href: 'https://www.linkedin.com/in/pratikshadake/',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
 
]

export default function Example() {
  return (
    <div className="bg-white px-6 py-12 sm:py-12 lg:px-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
		<h2 className="text-lg font-semibold text-indigo-600">Blog</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        	Reppin' Community Blog
	</p>
        <p className="mt-1 text-lg text-gray-600">
		Latest products, guides, member interactions etc.
        </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-500">
                    {post.date}
                  </time>
                  <span
                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </span>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={post.href}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  )
}

