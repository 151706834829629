const navigation = [
  {
    name: 'Blogs',
    href: '/blogs',
    blank: false,
  },
  
  {
    name: 'Terms',
    href: '/terms-of-service',
    blank: false,
  },
  {
    name: 'Privacy',
    href: '/privacy-policy',
    blank: false,
  },
  {
    name: 'Refund',
    href: '/refund-policy',
    blank: false,
  },
  {
    name: 'Graphia AI',
    href: 'https://www.graphia.ai',
    blank: true,
  },
   {
    name: 'LoveAdvisor AI',
    href: 'https://www.loveadvisor.ai',
    blank: true,
  }
]

export default function Example() {
  return (
    <footer className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
		<a 
  			href={item.href} 
  			className="text-sm leading-6 text-gray-300 hover:text-white"
  			target={item.blank ? "_blank" : undefined} 
  			rel={item.blank ? "noopener noreferrer" : undefined}
		>
  			{item.name}
		</a>

          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; 2024 Reppin. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

